"use client";
import RightArrow from "@/public/icon/right-bold.svg";
import "@/src/component/home/review-dt.scss";
import Image from "next/image";
import { Row, Col, Button, Skeleton } from "antd";
import ImageAntdCommon from "@/src/component/image/image-antd";
import Link from "next/link";
import { getBlog } from "@/src/app/api/blog/service";
import { useEffect, useState, useMemo } from "react";
import React from "react";

export default function ReviewDentistry({ reviewData }) {
  // const emptyArray = useMemo(() => Array.from({ length: 4 }), []);
  const [data, setData] = useState(reviewData);
  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const res = await getBlog(1, "review-nha-khoa", 2, "nha-khoa-cau-giay,nha-khoa-dong-da");
  //       setData(res.data?.result || []);
  //     } finally {
  //       setLoading(false);
  //     }
  //   })();
  // }, []);
  return (
    <>
      {data.length && (
        <div className="wp-reviewDentistry">
          <div className="wp-title">
            <div className="title">
              <h2>REVIEW NHA KHOA</h2>
            </div>
            {/* <div className="line"></div> */}
          </div>
          <div className="wp-content">
            <Row gutter={[45, 12]}>
              <Col xs={24} sm={24} md={12} xl={12}>
                {loading ? (
                  <Skeleton.Input
                    className="item-skeleton-blog-home main"
                    active
                  />
                ) : (
                  <Link href={"/blog/" + data[0]?.slug} className="wp-mainPost">
                    <div className="wp-mainPostImage">
                      <ImageAntdCommon
                        data={data && data[0]?.thumb}
                        className={"mainPostImage"}
                        width="100%"
                        height="100%"
                        alt={data[0]?.title}
                        loading={"lazy"}
                      />
                    </div>
                    <div className="wp-mainContent">
                      {/* <p className="blog">Review Nha Khoa</p> */}
                      <h3
                        className="title"
                        dangerouslySetInnerHTML={{
                          __html: data && data[0]?.title,
                        }}
                      ></h3>
                      <p
                        className="content-description"
                        dangerouslySetInnerHTML={{
                          __html: data && data[0]?.desc,
                        }}
                      ></p>
                      <Button className="see-detail">Xem chi tiết</Button>
                    </div>
                  </Link>
                )}
              </Col>
              <Col xs={24} sm={24} md={12} xl={12}>
                <div className="wp-otherPost">
                  {data?.map((item, index) => {
                    if (index >= 1) {
                      return (
                        <React.Fragment key={index}>
                          {loading ? (
                            <Skeleton.Input
                              className="item-skeleton-blog-home other"
                              active
                              key={index}
                            />
                          ) : (
                            <Link
                              href={"/blog/" + item?.slug}
                              className="wp-postItem"
                              key={index}
                            >
                              <div className="wp-postImage">
                                <ImageAntdCommon
                                  data={item?.thumb}
                                  className={"postImage"}
                                  width="100%"
                                  height="100%"
                                  alt={item?.title}
                                  loading={"lazy"}
                                />
                              </div>
                              <div className="wp-postItemContent">
                                <p
                                  className="postItemTitle"
                                  dangerouslySetInnerHTML={{
                                    __html: item?.title,
                                  }}
                                ></p>
                                <p
                                  className="postItemDesc"
                                  dangerouslySetInnerHTML={{
                                    __html: item?.desc,
                                  }}
                                ></p>
                                <Button className="see-detail">
                                  Xem chi tiết
                                </Button>
                              </div>
                            </Link>
                          )}
                        </React.Fragment>
                      );
                    }
                    return null;
                  })}
                </div>
              </Col>
            </Row>
          </div>
          <Button className="see-more-button" href="/blog/review-nha-khoa">
            Xem thêm <Image src={RightArrow} alt="Xem thêm"></Image>
          </Button>
        </div>
      )}
    </>
  );
}

"use client";
import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { AutoComplete, Button } from "antd";
import "./form-search-reduce.scss";
import { getClinicByName } from "./service";
import _ from "lodash";
import { strVNForSearch, toSlug } from "@/src/util/util";
import * as NProgress from "nprogress";
import { useRouter } from "next/navigation";
import { removeUndefined } from "@/src/util/util";
import Link from "next/link";
import ImageCommon from "../image/image";
import {
  PROVINCE,
  SERVICE,
  PROVINCE_FILLTED_FOR_SELECT,
} from "@/src/const/variable";
const { Option } = Select;
const FormItem = Form.Item;
import { createCache, extractStyle, StyleProvider } from "@ant-design/cssinjs";
const cache = createCache();
export default function FormSearchReduce() {
  const router = useRouter();
  const filterOption = (input, option) => {
    if (option.props.label) {
      return strVNForSearch(option.props.label).includes(strVNForSearch(input));
    } else {
      return false;
    }
  };
  const [form] = Form.useForm();
  const [optionsName, setOptionsName] = useState([]);
  const optionsCategoryService = SERVICE.map((item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const debounceOnChangeInput = _.debounce((event) => {
    requestSearch(event);
  }, 300);

  const onSearch = (searchText) => {
    debounceOnChangeInput(searchText);
  };
  const requestSearch = async (searchText) => {
    if (searchText) {
      const result = await getClinicByName(searchText);
      const newOption = result.map((item) => {
        return {
          value: item.id,
          label: (
            <Link href={`/phong-kham/${item.slug}`}>
              <div className="item-result-search">
                <ImageCommon data={item} style={"logo-on-search"} />
                <span>{item.name}</span>
              </div>
            </Link>
          ),
        };
      });
      setOptionsName(newOption);
    }
  };
  const onSubmit = async (longitude, latitude) => {
    // const fieldsValue = await form.validateFields();

    // let routeSearch = [];
    // if (fieldsValue.category_service) {
    //   const categoryServiceName = SERVICE.find(
    //     (item) => item.id == fieldsValue.category_service
    //   );
    //   routeSearch.push(`/${toSlug(categoryServiceName?.name)}`);
    //   delete fieldsValue.category_service;
    // }
    // if (!(longitude && latitude)) {
    //   if (form.getFieldValue("provinceName")) {
    //     routeSearch.push(`/${toSlug(form.getFieldValue("provinceName"))}`);
    //     if (form.getFieldValue("districtName")) {
    //       routeSearch.push(`/${toSlug(form.getFieldValue("districtName"))}`);
    //     }
    //   }
    // }
    // const parseValue = {
    //   ...fieldsValue,
    //   province_id: undefined,
    // };
    // removeUndefined(parseValue);

    // const param = new URLSearchParams(parseValue).toString();
    // const urlQuery =
    //   routeSearch.length > 0 ? routeSearch.join("") : "phong-kham";
    NProgress.start();
    router.push(`phong-kham`);
  };
  const handleResize = () => setWidth(window.innerWidth);
  const [width, setWidth] = useState(1024);

  useEffect(() => {
    handleResize();
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);
    }
  }, []);

  return (
    <StyleProvider cache={cache}>
      <Form form={form} className="wrapper-form-search-reduce">
        <FormItem className="button-submit">
          <Button
            className="button-submit-search button-blue"
            htmlType="submit"
            onClick={() => onSubmit()}
          >
            TÌM NHA KHOA GẦN BẠN
          </Button>
        </FormItem>
        {/* <FormItem className="button-submit location">
        <Button
          className="button-near-search button-white"
          onClick={() => onSubmitLocation()}
        >
          TÌM XUNG QUANH
        </Button>
      </FormItem> */}

        {/* {dataSearch?.map(item => <div>{item.name}</div>)} */}
      </Form>
    </StyleProvider>
  );
}

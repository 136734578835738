"use client";
import { Col, Row, Skeleton, Tabs } from "antd";
import ItemDT from "@/src/component/home/item-dt.js";
import styles from "@/src/component/home/reputable-dt.module.scss";
import "@/src/style/item-skeleton.css";
import "@/src/component/home/home.scss";

import { TOP_PROVINCE_HOT, REGION_TAG_FOR_REMARKABLE } from "@/src/const/const";
import { searchTopDentistry } from "@/src/component/home/search-province-dt.js";
import { useState, useEffect, useMemo } from "react";

export default function RemarkableDT({ remarkableData }) {
  const [activeKey, setActiveKey] = useState("hanoi");
  const [isLoading, setIsLoading] = useState(false);
  // const emptyArray = useMemo(() => Array.from({ length: 6 }), []);
  const [stateReputableData, setStateReputableData] = useState(
    remarkableData["hanoi"] || []
  );
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const result = await searchTopDentistry(1, null, "REMARKABLE", 9);
  //       setStateReputableData(result?.data || []);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   })();
  // }, []);

  const onChange = async (key) => {
    setActiveKey(key);
    setStateReputableData(remarkableData[key] || []);
    // try {
    //   let res = null;
    //   const queryKey = parseInt(key) ? { province: key } : { region: key };
    //   res = await searchTopDentistry(
    //     queryKey.province || null,
    //     queryKey.region || null,
    //     "REMARKABLE"
    //   );
    //   if (res?.data) {
    //     setStateReputableData(res?.data);
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  return (
    <>
      <div className={`${styles.reputableDentistry} reputable-dentistry`}>
        <div className={styles.wpTitle}>
          <div className={styles.title}>
            <h2>Nha khoa nổi bật</h2>
          </div>
          <p className={styles.subTitle}>
            Top nha khoa uy tín được đánh giá bởi NhaKhoaHub & hơn 50.000+ người
            dùng
          </p>
        </div>

        <Tabs
          items={REGION_TAG_FOR_REMARKABLE}
          activeKey={activeKey}
          onChange={(key) => {
            onChange(key);
          }}
          tabBarExtraContent={""}
        />
        <Tabs
          items={TOP_PROVINCE_HOT}
          activeKey={activeKey}
          onChange={(key) => {
            onChange(key);
          }}
          tabBarExtraContent={""}
        />

        <div className={styles.listDentistry}>
          <Row gutter={[{ xl: 30, md: 30, xs: 0, sm: 12 }, 20]}>
            {stateReputableData?.map((item, index) => (
              <Col xs={24} sm={24} md={12} xl={8} key={index}>
                {!isLoading && <ItemDT data={item} type={"REMARKABLE"} />}
                {isLoading && (
                  <div className="wpSkeletonItem">
                    <div className="wpSkeletonInfo">
                      <Skeleton.Avatar className="skeletonLogo" active />
                      <div className="wpSkeletonDetails">
                        <Skeleton.Input className="skeletonInput1" active />
                        <Skeleton.Input className="skeletonInput2" active />
                        <div className="wpSkeletonButton">
                          <Skeleton.Button className="skeletonButton1" active />
                          <Skeleton.Button className="skeletonButton2" active />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </>
  );
}

"use client";
import "./introduce-section.scss";
import Image from "next/image";
import nhakhoahubReason1 from "@/public/image/nhakhoahub-reason-1.webp";
import nhakhoahubReason2 from "@/public/image/nhakhoahub-reason-2.webp";
import nhakhoahubReason3 from "@/public/image/nhakhoahub-reason-3.webp";
import nhakhoahubReason4 from "@/public/image/nhakhoahub-reason-4.webp";
import { useState } from "react";
import iconPlay from "@/public/icon/youtube-play-icon.png";
import { useRouter } from "next/navigation";
import { Button } from "antd";
import RightArrow from "@/public/icon/right-bold.svg";
import {
  wrapperRouterPush,
} from "@/src/util/util";

export default function IntroduceSection() {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const router = useRouter();
  const handleLoadVideo = () => {
    setIsVideoLoaded(true);
  };

  return (
    <div>
      <div className="wp-nhakhoahub-story">
        <div className="nhakhoahub-story">
          <div className="wp-content">
            <div>
              Câu chuyện NhaKhoa<span style={{ color: "#00c5d7" }}>Hub</span>
            </div>
            <p>
              NhaKhoa<span style={{ color: "#00c5d7" }}>Hub</span> là nền tảng
              tìm kiếm nha khoa và review nha khoa hàng đầu Việt Nam. Chúng tôi
              ra đời với sứ mệnh là "cầu nối" khách hàng với các cơ sở nha khoa
              tốt nhất, chất lượng cao trên cả nước.
              <br />
              <br />
              Với hệ thống 5.000+ cơ sở nha khoa cùng những đánh giá khách quan,
              trung thực đến từ người dùng thật, khách hàng có thể lựa chọn địa
              chỉ nha khoa uy tín và gần nhất phù hợp với nhu cầu của bản thân.
            </p>
            <Button className="see-more-button" onClick={() => {
              wrapperRouterPush(router, "/ve-chung-toi");
              // router.push("/ve-chung-toi")
            }}>
              Xem thêm <Image src={RightArrow} alt="intro-arrow"></Image>
            </Button>
          </div>
          <div className="wp-image-story">
            {!isVideoLoaded ? (
              <div className="video-placeholder" onClick={handleLoadVideo}>
                <Image
                  src="https://img.youtube.com/vi/D9LnBCKjYAw/maxresdefault.jpg"
                  alt="Video Placeholder"
                  className="image-story"
                  width={575}
                  height={323}
                />
                <Image src={iconPlay} className="play-button" width={65} height={65} alt="play-button"/>
              </div>
            ) : (
              <iframe
                className="image-story"
                src="https://www.youtube.com/embed/D9LnBCKjYAw?autoplay=1&rel=0&controls=0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
                loading="lazy"
              ></iframe>
            )}
          </div>
        </div>
      </div>
      <div className="wp-nhakhoahub-reason">
        <div className="nhakhoahub-reason">
          <div className="title">
            Tại sao nên lựa chọn NhaKhoa
            <span style={{ color: "#00c5d7" }}>Hub</span>?
          </div>
          <div className="sub-title">
            Với hệ sinh thái hơn <b>5.000+ cơ sở nha khoa</b> trên cả nước,
            <br /> NhaKhoa<span style={{ color: "#00c5d7" }}>Hub</span> giúp bạn
            dễ dàng so sánh, lựa chọn nha khoa phù hợp
          </div>
          <div className="wp-content">
            <div className="item-content">
              <div className="wp-image-content">
                <Image
                  src={nhakhoahubReason1}
                  className="image-content"
                  alt="Tìm kiếm nha khoa nhanh chóng"
                ></Image>
              </div>
              <span className="title-content">
                Tìm kiếm nha khoa nhanh chóng
              </span>
              <p>
                NhaKhoaHub tích hợp công nghệ tìm kiếm thông minh theo từng khu
                vực, dịch vụ chỉnh nha, giá thành,... giúp khách hàng tiết kiệm
                thời gian tìm kiếm.
              </p>
            </div>
            <div className="item-content">
              <div className="wp-image-content">
                <Image
                  src={nhakhoahubReason2}
                  className="image-content"
                  alt="Review nha khoa chân thực, chi tiết"
                ></Image>
              </div>
              <div className="title-content">
                Review nha khoa chân thực, chi tiết
              </div>
              <p>
                NhaKhoaHub cung cấp thông tin review nha khoa chi tiết, giúp
                khách hàng lựa chọn được địa chỉ nha khoa uy tín, phù hợp nhất
                với nhu cầu cá nhân.
              </p>
            </div>
            <div className="item-content">
              <div className="wp-image-content">
                <Image
                  src={nhakhoahubReason3}
                  className="image-content"
                  alt="Hệ sinh thái 5.000+ cơ sở nha khoa"
                ></Image>
              </div>
              <div className="title-content">
                Hệ sinh thái 5.000+ cơ sở nha khoa
              </div>
              <p>
                NhaKhoaHub là đối tác của hơn 5.000+ cơ sở nha khoa trên khắp cả
                nước, chúng tôi tự tin sẽ kết nối khách hàng đến với những nha
                khoa uy tín phù hợp nhất.
              </p>
            </div>
            <div className="item-content">
              <div className="wp-image-content">
                <Image
                  src={nhakhoahubReason4}
                  className="image-content"
                  alt="Không phát sinh thêm chi phí"
                ></Image>
              </div>
              <div className="title-content">Không phát sinh thêm chi phí</div>
              <p>
                Khi đặt lịch tư vấn qua nền tảng NhaKhoaHub, khách hàng sẽ được
                kết nối trực tiếp đến nha khoa và tư vấn miễn phí mà không phát
                sinh thêm bất kỳ chi phí nào.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

"use client";
import Image from "next/image";
import { useRouter } from "next/navigation";
import styles from "@/src/component/home/item-dt.module.scss";
import { IMAGE_TYPE } from "@/src/const/const";
import ImageCommon from "@/src/component/image/image";
import { wrapperRouterPush } from "@/src/util/util";
import { Button } from "antd";
import { useEffect } from "react";
import iconStar from "@/public/icon/icon-start-3d.svg";
import "./item-skeleton.scss";
import Link from "next/link";

export default function ItemDT({ data, type }) {
  const logo = data?.images[0];
  const router = useRouter();
  // const onShowDetail = (slug) => {
  //   wrapperRouterPush(router, `/phong-kham/${slug}`);
  // };

  return (
    <div style={{ height: "100%" }}>
      {type === "REMARKABLE" ? (
        <Link href={`/phong-kham/${data?.slug}`}>
          <div
            className={`${styles.dentistryItem}`}
            // onClick={() => onShowDetail(data?.slug)}
          >
            <div className={styles.dentistryInfo}>
              <div style={{ display: "flex" }}>
                <div className={styles.dentistryLogo}>
                  <ImageCommon
                    data={logo}
                    style={styles.img}
                    alt={data?.name}
                    loading="lazy"
                  />
                </div>
                <div className={styles.dentistryDetails}>
                  <div className={styles.coverContainer}>
                    <h4 className={styles.dentistryName}>{data?.name}</h4>
                  </div>
                  {data?.detail_address && (
                    <div className={styles.clinicAddress}>
                      {data?.detail_address}
                    </div>
                  )}
                  <div className={styles.thirdLineTag}>
                    <Button className="see-detail">Xem chi tiết</Button>
                    {data?.overall_average != 0 && (
                      <div className={styles.ratingPoint}>
                        <div className={styles.point}>
                          {data?.overall_average?.toFixed(1)}
                        </div>
                        <Image
                          className={styles.iconRatingStar}
                          src={iconStar}
                          alt="icon rating start"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      ) : (
        <div
          className={styles.itemTopClinic}
          onClick={() => onShowDetail(data?.slug)}
        >
          <ImageCommon data={logo} style={styles.img} />
        </div>
      )}
    </div>
  );
}

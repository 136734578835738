import wpAxios from "@/src/util/request";
import { BASE_URL } from "../../const/const";


export async function searchTopDentistry(province_id, region, order_by, limit) {
  const resutl = await wpAxios().get(`${BASE_URL}/clinic/search`, {
    params: {
      page: 1,
      limit: limit ? limit : 9,
      province_id: province_id ? province_id : null,
      region: region ? region : null,
      order_by: order_by ? order_by : null,
    },
  });
  return resutl.data;
}


export async function searchTopByIp( type, limit) {
  const resutl = await wpAxios().get(`${BASE_URL}/locating/clinicsNearByIp`, {
    params: {
      page: 1,
      limit: limit ? limit : 9,
      type: type ? type : null,
    },
  });
  return resutl.data;
}

export async function searchRemarkableClinics() {
  const resutl = await wpAxios().get(`${BASE_URL}/clinic/remarkableClinic`);
  return resutl.data;
}
